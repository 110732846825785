<template>
    <section class="login-section">
        <!-- Animated Casino Background Canvas -->
        <canvas id="casinoCanvas" class="casino-bg"></canvas>
        
        <div class="login-container">
            <div class="logo-container">
                <img src="/assets/logo.png" alt="CryptonBets Affiliate Logo">
                <h1>Affiliate Portal</h1>
                <p class="tagline">Your gateway to crypto gambling profits</p>
            </div>
            
            <div class="login-card">
                <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
                
                <form class="login-form" @submit.prevent="handleLogin" autocomplete="off">
                    <transition name="fade" mode="out-in">
                        <div v-if="!requires_email_2fa && !user_requires_2fa" key="login">
                            <div class="input-group">
                                <label for="username">Username</label>
                                <div class="input-container">
                                    <i class=""></i>
                                    <input type="text" id="username" v-model="username" placeholder="Enter your username" autocomplete="off">
                                </div>
                            </div>
                            
                            <div class="input-group">
                                <label for="password">Password</label>
                                <div class="input-container">
                                    <i class=""></i>
                                    <input type="password" id="password" v-model="password" placeholder="Enter your password" autocomplete="off">
                                    <i class="" @click="togglePasswordVisibility"></i>
                                </div>
                            </div>
                            
                            <button type="submit" class="login-btn">
                                <span>Sign In</span>
                                <i class=""></i>
                            </button>
                        </div>
                        
                        <div v-else-if="user_requires_2fa" key="2fa">
                            <div class="input-group">
                                <label for="google_code">Google Authenticator Code</label>
                                <div class="input-container">
                                    <i class=""></i>
                                    <input type="text" id="google_code" v-model="google_code" placeholder="Enter 6-digit code" required>
                                </div>
                            </div>
                            <button type="submit" class="login-btn">
                                <span>Verify</span>
                                <i class=""></i>
                            </button>
                        </div>
                        
                        <div v-else-if="requires_email_2fa" key="email2fa">
                            <div class="input-group">
                                <label for="email_code">Email Verification Code</label>
                                <div class="input-container">
                                    <i class=""></i>
                                    <input type="text" id="email_code" v-model="email_code" placeholder="Enter code sent to your email" required>
                                </div>
                            </div>
                            <button type="submit" class="login-btn">
                                <span>Verify</span>
                                <i class=""></i>
                            </button>
                        </div>
                    </transition>
                </form>
                
                <div class="links-container">
                    <a href="https://cryptonbets.com/pages?data=forgot-affiliate-account" target="_blank" class="link">
                        <i class=""></i> Forgot Account?
                    </a>
                    <a href="https://cryptonbets.com/pages?data=become-affiliate" target="_blank" class="link">
                        <i class=""></i> Become an Affiliate
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "Login",
    data() {
        return {
            username: "",
            password: "",
            errorMessage: '',
            user_requires_2fa: false,
            requires_email_2fa: false,
            google_code: '',
            email_code: '',
            showPassword: false,
            canvasAnimation: null
        };
    },
    methods: {
        ...mapActions(["login"]),
        async handleLogin() {
            try {
                const user = {
                    username: this.username, 
                    password: this.password, 
                    google_code: this.google_code,
                    email_code: this.email_code
                };
                await this.login(user);
                this.$router.push("/");
            } catch (e) {
                if (this.$store.state?.user_requires_2fa) {
                    this.user_requires_2fa = true
                }
                if (this.$store.state?.requires_email_2fa) {
                    this.requires_email_2fa = true
                }
                this.errorMessage = this.$store.state.error_message
            }
        },
        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
            const passwordInput = document.getElementById('password');
            if (passwordInput) {
                passwordInput.type = this.showPassword ? 'text' : 'password';
            }
        },
        initCanvasAnimation() {
            const canvas = document.getElementById('casinoCanvas');
            const ctx = canvas.getContext('2d');
            
            // Set canvas size
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
            
            // Casino elements
            const elements = [];
            const symbols = ['♦', '♠', '♥', '♣', '7', '$', '€', '¥', '₿'];
            const colors = ['#ff5252', '#4caf50', '#2196f3', '#ffeb3b', '#9c27b0'];
            
            // Create initial elements
            for (let i = 0; i < 50; i++) {
                elements.push({
                    x: Math.random() * canvas.width,
                    y: Math.random() * canvas.height,
                    size: Math.random() * 20 + 10,
                    speed: Math.random() * 2 + 1,
                    symbol: symbols[Math.floor(Math.random() * symbols.length)],
                    color: colors[Math.floor(Math.random() * colors.length)],
                    rotation: Math.random() * 360,
                    rotationSpeed: Math.random() * 2 - 1
                });
            }
            
            // Animation loop
            const animate = () => {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                
                // Draw semi-transparent overlay
                ctx.fillStyle = 'rgba(15, 15, 26, 0.85)';
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                
                // Draw elements
                elements.forEach(el => {
                    ctx.save();
                    ctx.translate(el.x, el.y);
                    ctx.rotate(el.rotation * Math.PI / 180);
                    
                    ctx.font = `${el.size}px Arial`;
                    ctx.fillStyle = el.color;
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    ctx.fillText(el.symbol, 0, 0);
                    
                    ctx.restore();
                    
                    // Update position
                    el.y += el.speed;
                    el.rotation += el.rotationSpeed;
                    
                    // Reset if off screen
                    if (el.y > canvas.height + el.size) {
                        el.y = -el.size;
                        el.x = Math.random() * canvas.width;
                    }
                });
                
                this.canvasAnimation = requestAnimationFrame(animate);
            };
            
            animate();
            
            // Handle resize
            window.addEventListener('resize', () => {
                canvas.width = window.innerWidth;
                canvas.height = window.innerHeight;
            });
        }
    },
    mounted() {
        this.initCanvasAnimation();
    },
    beforeDestroy() {
        if (this.canvasAnimation) {
            cancelAnimationFrame(this.canvasAnimation);
        }
    }
};
</script>

<style scoped>
/* Base Styles */
:root {
    --primary: #ffeb3b; /* Casino red */
    --primary-dark: #ffeb3b;
    --accent: #ffeb3b; /* Gold/yellow */
    --background: #0f0f1a;
    --card-bg: rgba(26, 26, 46, 0.9);
    --text: #ffffff;
    --text-secondary: #b8b8d1;
    --error: #ff7675;
    --success: #55efc4;
    --casino-chip: #ffeb3b;
    --casino-felt: #2e7d32;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.login-section {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.casino-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.login-container {
    width: 100%;
    max-width: 420px;
    padding: 2rem;
    z-index: 10;
}

.logo-container {
    text-align: center;
    margin-bottom: 2rem;
}

.logo-container img {
    max-width: 120px;
    margin-bottom: 1rem;
    filter: drop-shadow(0 0 8px rgba(255, 82, 82, 0.5));
}

.logo-container h1 {
    color: var(--text);
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: 1px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    margin-bottom: 0.5rem;
}

.logo-container .tagline {
    color: var(--accent);
    font-size: 0.9rem;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.login-card {
    background-color: var(--card-bg);
    border-radius: 16px;
    padding: 2rem;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
    border: 1px solid #ffeb3b;
    backdrop-filter: blur(5px);
}

.input-group {
    margin-bottom: 1.5rem;
}

.input-group label {
    display: block;
    color: var(--text-secondary);
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    font-weight: 500;
}

.input-container {
    position: relative;
    display: flex;
    align-items: center;
}

.input-container i {
    position: absolute;
    left: 16px;
    color: var(--text-secondary);
    font-size: 1rem;
}

.input-container .toggle-password {
    left: auto;
    right: 16px;
    cursor: pointer;
    transition: color 0.2s;
}

.input-container .toggle-password:hover {
    color: var(--accent);
}

.input-container input {
    width: 100%;
    padding: 14px 16px 14px 44px;
    background-color: rgba(255, 255, 255, 0.05);
    border: 1px solid #ffeb3b;
    border-radius: 10px;
    color: var(--text);
    font-size: 0.95rem;
    transition: all 0.3s ease;
}

.input-container input:focus {
    outline: none;
    border-color: var(--primary);
    box-shadow: 0 0 0 2px #ffeb3b;
}

.login-btn {
    width: 100%;
    padding: 14px;
    background: linear-gradient(135deg, var(--primary), var(--primary-dark));
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition: all 0.3s ease;
    margin-top: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.login-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 7px 14px #ffeb3b;
}

.login-btn:active {
    transform: translateY(0);
}

.error-message {
    color: var(--error);
    background-color: rgba(255, 118, 117, 0.1);
    border: 1px solid var(--error);
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 1.5rem;
    font-size: 0.9rem;
    text-align: center;
}

.links-container {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.link {
    color: var(--text-secondary);
    text-decoration: none;
    font-size: 0.85rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    transition: color 0.2s;
}

.link:hover {
    color: var(--accent);
}

/* Transitions */
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s ease;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}

/* Responsive */
@media (max-width: 480px) {
    .login-container {
        padding: 1rem;
    }
    
    .login-card {
        padding: 1.5rem;
    }
    
    .logo-container img {
        max-width: 100px;
    }
    
    .logo-container h1 {
        font-size: 1.5rem;
    }
}

@media (max-height: 700px) {
    .logo-container {
        margin-bottom: 1rem;
    }
    
    .logo-container img {
        max-width: 80px;
        margin-bottom: 0.5rem;
    }
}
</style>

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css">